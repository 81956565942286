import React, { useState } from 'react';
import { useWeb3React } from '@web3-react/core';

import Button from '../Button';
import SettingPopup from './SettingPopup';
import WalletPopup from '../WalletPopup';
import ChevronIcon from '../../assets/images/chevron-white.svg';
import { useSelector } from 'react-redux';

const ConnectWallet = (props) => {
    const {account} = useWeb3React();
    const { locale } = useSelector(state => state.common);
    const [openWalletPopup, setOpenWalletPopup] = useState(false);
    const [openSettingPopup, setOpenSettingPopup] = useState(false);

    return (
        <>
            {account ? (
                <div className='relative'>
                    <Button
                        type='outlined'
                        className='h-50px'
                        onClick={() => setOpenSettingPopup(true)}
                    >
                        <span className='font-montserrat font-bold pl-4'>{account && `${account.slice(0, 6)}...${account.slice(account.length - 4, account.length)}`} &nbsp;</span>
                        <img src={ChevronIcon} alt="" className='w-6 h-6 ml-2'/>
                    </Button>
                    {openSettingPopup && (
                        <SettingPopup
                            setOpenPopup={setOpenSettingPopup}
                        />
                    )}
                </div>
            ) : (
                <Button
                    type='contained'
                    className='h-50px px-5 md:px-16'
                    onClick={() => {
                        setOpenWalletPopup(true);
                    }}
                >
                    {locale['connect_wallet']}
                </Button>
            )}
            {openWalletPopup && (
                <WalletPopup
                    setOpenPopup={setOpenWalletPopup}
                />
            )}
        </>
    )
}

export default ConnectWallet;