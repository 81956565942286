import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

const Button = ({
    className,
    type,
    max,
    compact,
    nonHover,
    url,
    onClick,
    children
}) => {
    const classes = classNames(
        'flex justify-center items-center text-15 rounded-full cursor-pointer',
        {'bg-disabled': type === 'disabled'},
        {'bg-green-primary': type === 'contained'},
        {'text-green-primary border border-green-primary': type === 'outlined'},
        {'px-5': !compact},
        {'hover:opacity-80': !nonHover && type !== 'disabled'},
        {'w-full': !max},
        className
    );
    const isExternal = url
    ? url.indexOf('://') > 0 || url.indexOf('//') === 0
    : false;

    if (isExternal) {
        return (
            <a href={url} target={'_blank'} rel="noreferrer" className={classes}>
                {children}
            </a>
        )
    }
    
    if (onClick) {
        return (
            <button className={classes} disabled={type === 'disabled'} onClick={onClick}>
                {children}
            </button>
        )
    }

    return (
        <Link to={url || '/'} className={classes}>
            {children}
        </Link>
    )
}

export default Button;