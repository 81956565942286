import Button from "../../../components/Button";

const MigrationInputItem = ({
  tokenName,
  tokenBalance,
  tokenIcon,
  value,
  amount,
  onChange,
  onMax,
  price,
  isMax,
  disabled,
}) => {
  return (
    <div className="flex flex-col items-start px-4 md:px-6 py-4 md:py-5 bg-secondary rounded-xl w-96 shadow-2xl shadow-green-900/50 drop-shadow max-lg:w-full">
      <div className="flex justify-between w-10/12 max-sm:w-full">
        <p className="text-15 text-gray-light font-medium">{tokenName}</p>
        <div className="flex gap-1">
          <p className="text-15 text-gray-light font-medium">Balance</p>
          <p className="text-15 font-medium">{tokenBalance.toFixed(4)}</p>
        </div>
      </div>

      <div className="flex items-end gap-2 py-2 w-10/12 max-sm:w-full">
        <img src={tokenIcon} alt="" className="w-11  h-11" />
        <div className="flex items-end justify-between border border-transparent border-b-gray-dark py-1">
          <input
            type="text"
            inputMode="decimal"
            className="border-none outline-none text-19 w-10/12 bg-transparent text-ellipsis"
            placeholder="0.00"
            value={value}
            onChange={(e) => onChange(e.target.value)}
            disabled={disabled}
          />
          {isMax && (
            <Button
              type="outlined"
              compact
              max
              className="h-auto w-fit rounded-xl text-auto py-1 px-2 border-green-800 mb-1"
              onClick={onMax}
            >
              <span className="font-montserrat font-bold text-sm">MAX</span>
            </Button>
          )}
        </div>
      </div>
      <p className="text-10 md:text-12 text-gray-light mt-5">
        = ${(amount * price).toFixed(2)}
      </p>
    </div>
  );
};

export default MigrationInputItem;
