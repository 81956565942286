import React from 'react';

const Footer = () => {
  return (
    <div className='py-10'>
      <div className='flex flex-wrap justify-center items-center text-15 text-gray-light'>
        <a className='m-4 hover:text-white' href="https://www.betero.io/cookie_policy.pdf" target="_blank" rel="noreferrer">
          Cookie Policy
        </a>
        <a className='m-4 hover:text-white' href="https://www.betero.io/privacy_policy.pdf" target="_blank" rel="noreferrer">
          Privacy Policy
        </a>
        <a className='m-4 hover:text-white' href="https://www.betero.io/terms.pdf" target="_blank" rel="noreferrer">
          Terms & Conditions
        </a>
        <a className='m-4 hover:text-white' href="https://www.betero.io/risk_disclaimer.pdf" target="_blank" rel="noreferrer">
          Risk Disclaimer
        </a>
      </div>
    </div>
  )
}

export default Footer;