import React from "react";
import { useWeb3React } from "@web3-react/core";
import LogoutIcon from "../../assets/images/logout.svg";
import { useDispatch } from "react-redux";
import { actions } from "../../redux/wallet.redux";
import useAnalyticsEventTracker from "../../services/useAnalyticsEventTracker";

export default function SettingPopup(props) {
  const { setOpenPopup } = props;
  const { connector } = useWeb3React();
  const dispatch = useDispatch();
  const gaEventTracker = useAnalyticsEventTracker("Wallet");

  const disconnect = async () => {
    if (connector && connector.deactivate) {
      connector.deactivate();
    }
    connector.resetState();
    gaEventTracker("Disconnect");
    dispatch(actions.updateSelectedWalletAction(undefined));
    setOpenPopup(false);
  };

  return (
    <>
      <div
        className="fixed w-full h-screen inset-0 z-10"
        onClick={() => setOpenPopup(false)}
      />
      <div
        className="absolute right-1 md:right-4 top-23 bg-gray-900 shadow cursor-pointer px-6 py-2.5 z-50 rounded-lg flex items-center"
        onClick={() => disconnect()}
      >
        <span>Disconnect</span>
        <img src={LogoutIcon} alt="" className="w-5 h-5 ml-3" />
      </div>
    </>
  );
}
