import React, { useEffect, useState, useCallback } from "react";
import { Contract } from "ethers";

import { COMPOUNDING_PERIOD, DECIMALS } from "../../abis/constants";
import { parseBNumber } from "../../services/utils";
// import classNames from 'classnames';
import TokenDetailPopup from "../TokenDetailPopup";
import TokenMainInfo from "../TokenMainInfo";
// import StakeTokenPopup from '../StakeTokenPopup';
import TermsPopup from "../TermsPopup";
import DisclaimerPopup from "../DisclaimerPopup";
// import { useSelector } from 'react-redux';

const TokenItem = ({ contract, chainId, account, provider, getBTEPrice }) => {
  // const refreshCnt = useSelector(state => state.common.refreshCount);
  const [openDetailPopup, setOpenDetailPopup] = useState(false);
  const [userStakedToken, setUserStakedToken] = useState(0);
  const [userStakedBTE, setUserStakedBTE] = useState(0);
  const [userStakedBNB, setUserStakedBNB] = useState(0);
  const [userStakedETH, setUserStakedETH] = useState(0);
  const [userStakedCRO, setUserStakedCRO] = useState(0);
  const [userEarnedBTE, setUserEarnedBTE] = useState(0);
  const [stakedBTE, setStakedBTE] = useState(0);
  const [stakedBNB, setStakedBNB] = useState(0);
  const [stakedBUSD, setStakedBUSD] = useState(0);
  const [stakedCRO, setStakedCRO] = useState(0);
  const [stakedETH, setStakedETH] = useState(0);
  const [liquidity, setLiquidity] = useState(0);
  const [rewardPerYear, setRewardPerYear] = useState(0);
  const [apy, setApy] = useState(0);
  const [apr, setApr] = useState(0);
  const [btePrice, setBtePrice] = useState(0);
  const [lpPrice, setLpPrice] = useState(0);
  const [vesting, setVesting] = useState(0);
  const [openTermsPopup, setOpenTermsPopup] = useState(false);
  const [openDisclaimerPopup, setOpenDisclaimerPopup] = useState(false);

  const initProcess = useCallback(async () => {
    if (!chainId || !provider) {
      return;
    }
    try {
      const bte_price = await getBTEPrice();
      setBtePrice(bte_price);
      const farmContract = new Contract(
        contract.address,
        contract.abi,
        provider
      );
      const tokenContract = new Contract(
        contract.tokenAddress,
        contract.tokenAbi,
        provider
      );

      const blocktime = chainId === 56 ? 3 : chainId === 25 ? 5.8 : 13.2;
      const rewardCnt = await farmContract.rewardPerBlock();
      const rewardAmount = parseBNumber(rewardCnt, DECIMALS);
      const reward_per_year =
        ((365 * 24 * 3600) / blocktime) * rewardAmount * bte_price;
      setRewardPerYear(reward_per_year);
      const vestTime = await farmContract.vestingTime(); // in
      setVesting(vestTime.toNumber() * 1000); // in milisecond

      let user_staked_token = 0;
      if (account) {
        const userInfo = await farmContract.userInfo(account);
        user_staked_token = parseBNumber(userInfo?.amount || 0, DECIMALS);
        setUserStakedToken(user_staked_token);

        const pendingReward = await farmContract.pendingReward(account);
        const user_earned_BTE = parseBNumber(pendingReward, DECIMALS);
        setUserEarnedBTE(user_earned_BTE);
      } else {
        setUserStakedToken(0);
        setUserEarnedBTE(0);
      }

      if (contract.type === "MULTIPLE") {
        const balance = await tokenContract.balanceOf(contract.address);
        const stakedLp = parseBNumber(balance, DECIMALS); // LP staked

        const totalSupply = await tokenContract.totalSupply(); // total LP
        const totalLp = parseBNumber(totalSupply, DECIMALS);
        if (totalLp <= 0) return;

        // get reserves on the token
        const reserves = await tokenContract?.getReserves();
        let totalBte = 0;
        let totalBnb = 0;
        let totalBusd = 0;
        let totalEth = 0;
        let totalCro = 0;
        if (contract.secondToken === "BNB") {
          totalBte = parseBNumber(reserves._reserve0, DECIMALS); // total BTE
          totalBnb = parseBNumber(reserves._reserve1, DECIMALS); // total BNB
        } else if (contract.secondToken === "BUSD") {
          totalBte = parseBNumber(reserves._reserve0, DECIMALS); // total BTE
          totalBusd = parseBNumber(reserves._reserve1, DECIMALS); // total BUSD
        } else if (contract.secondToken === "ETH") {
          totalBte = parseBNumber(reserves._reserve0, DECIMALS); // total BTE
          totalEth = parseBNumber(reserves._reserve1, DECIMALS); // total ETH
        } else if (contract.secondToken === "CRO") {
          totalBte = parseBNumber(reserves._reserve0, DECIMALS); // total BTE
          totalCro = parseBNumber(reserves._reserve1, DECIMALS); // total ETH
        }
        const staked_BTE = (totalBte * stakedLp) / totalLp;
        const staked_BNB = (totalBnb * stakedLp) / totalLp;
        const staked_BUSD = (totalBusd * stakedLp) / totalLp;
        const staked_ETH = (totalEth * stakedLp) / totalLp;
        const staked_CRO = (totalCro * stakedLp) / totalLp;
        const user_statked_BTE = (user_staked_token * totalBte) / totalLp;
        const user_statked_BNB = (user_staked_token * totalBnb) / totalLp;
        const user_statked_ETH = (user_staked_token * totalEth) / totalLp;
        const user_statked_CRO = (user_staked_token * totalCro) / totalLp;

        const lp_price = (totalBte * bte_price * 2) / totalLp; // one LP price
        setLpPrice(lp_price);
        const liquidityAmount = stakedLp * lp_price;

        const anualPercentReward =
          liquidityAmount > 0 ? (reward_per_year / liquidityAmount) * 100 : 0;
        const anualPercentYield =
          (1 + anualPercentReward / 100 / COMPOUNDING_PERIOD) **
            COMPOUNDING_PERIOD -
          1;
        const apyAmount = anualPercentYield * 100;

        setStakedBTE(staked_BTE);
        setStakedBNB(staked_BNB);
        setStakedBUSD(staked_BUSD);
        setStakedETH(staked_ETH);
        setStakedCRO(staked_CRO);
        setUserStakedBTE(user_statked_BTE);
        setUserStakedBNB(user_statked_BNB);
        setUserStakedETH(user_statked_ETH);
        setUserStakedCRO(user_statked_CRO);
        setLiquidity(liquidityAmount);
        setApr(anualPercentReward);
        setApy(apyAmount);
      } else if (contract.type === "SINGLE") {
        const response = await farmContract.stakedTokenSupply();
        const staked_BTE = parseBNumber(response, DECIMALS);
        const liquidityAmount = staked_BTE * bte_price;

        const anualPercentReward =
          liquidityAmount > 0 ? (reward_per_year / liquidityAmount) * 100 : 0;
        const anualPercentYield =
          (1 + anualPercentReward / 100 / COMPOUNDING_PERIOD) **
            COMPOUNDING_PERIOD -
          1;
        const apyAmount = anualPercentYield * 100;

        setStakedBTE(staked_BTE);
        setUserStakedBTE(user_staked_token);
        setLiquidity(liquidityAmount);
        setApr(anualPercentReward);
        setApy(apyAmount);
      }
    } catch (err) {
      console.log(err);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contract, account]);

  useEffect(() => {
    initProcess();
  }, [initProcess]);

  useEffect(() => {
    const interval = setInterval(() => {
      initProcess();
    }, 10000);

    return () => clearInterval(interval);
  }, [initProcess]);

  return (
    <div>
      <TokenMainInfo
        contract={contract}
        vesting={vesting}
        userStakedToken={userStakedToken}
        userEarnedBTE={userEarnedBTE}
        apy={apy}
        btePrice={btePrice}
        lpPrice={lpPrice}
        onClick={setOpenDetailPopup}
      />

      {openDetailPopup && (
        <TokenDetailPopup
          contract={contract}
          vesting={vesting}
          userEarnedBTE={userEarnedBTE}
          userStakedToken={userStakedToken}
          userStakedBTE={userStakedBTE}
          userStakedBNB={userStakedBNB}
          userStakedETH={userStakedETH}
          userStakedCRO={userStakedCRO}
          btePrice={btePrice}
          lpPrice={lpPrice}
          stakedBTE={stakedBTE}
          stakedBNB={stakedBNB}
          stakedBUSD={stakedBUSD}
          stakedCRO={stakedCRO}
          stakedETH={stakedETH}
          liquidity={liquidity}
          apy={apy}
          apr={apr}
          rewardPerYear={rewardPerYear}
          setOpenPopup={setOpenDetailPopup}
        />
      )}

      {openTermsPopup && <TermsPopup setOpenPopup={setOpenTermsPopup} />}
      {openDisclaimerPopup && (
        <DisclaimerPopup setOpenPopup={setOpenDisclaimerPopup} />
      )}
    </div>
  );
};

export default TokenItem;
