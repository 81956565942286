import { initializeConnector } from "@web3-react/core";
import { MetaMask } from "@web3-react/metamask";
import { Network } from "@web3-react/network";
import { Connector } from "@web3-react/types";
import { WalletConnect as WalletConnectV2 } from "@web3-react/walletconnect-v2";
import { RPC_URLS } from "../abis/constants";

export const ConnectionType = {
  INJECTED: "INJECTED",
  WALLET_CONNECT2: "WALLET_CONNECT2",
  NETWORK: "NETWORK",
};

function onError(error) {
  console.debug(`web3-react error: ${error}`);
}

const [web3Network, web3NetworkHooks] = initializeConnector(
  (actions) => new Network({ actions, urlMap: RPC_URLS, defaultChainId: 1 })
);

const [web3Injected, web3InjectedHooks] = initializeConnector(
  (actions) => new MetaMask({ actions, onError })
);
const [web3WalletConnect, web3WalletConnectHooks] = initializeConnector(
  (actions) =>
    new WalletConnectV2({
      actions,
      options: {
        projectId: 'ab9a9c32be73f29d1e09ec6555f4068e',
        chains: [1],
        optionalChains: [25, 56, 137],
        rpcMap: RPC_URLS,
        showQrModal: true,
      },
    })
);

export const networkConnection = {
  name: "Network",
  connector: web3Network,
  hooks: web3NetworkHooks,
  type: ConnectionType.NETWORK,
};

export const injectedConnection = {
  name: "MetaMask",
  connector: web3Injected,
  hooks: web3InjectedHooks,
  type: ConnectionType.INJECTED,
  overrideActivate: () => window.open("https://metamask.io/", "inst_metamask"),
};

export const walletConnectConnection = {
  name: "WalletConnect",
  connector: web3WalletConnect,
  hooks: web3WalletConnectHooks,
  type: ConnectionType.WALLET_CONNECT2,
};

export const getConnections = () => {
  return [injectedConnection, walletConnectConnection, networkConnection];
};

export const useGetConnection = () => {
  return (c) => {
    if (c instanceof Connector) {
      const connection = getConnections().find(
        (connection) => connection.connector === c
      );
      if (!connection) {
        throw Error("unsupported connector");
      }
      return connection;
    } else {
      switch (c) {
        case ConnectionType.INJECTED:
          return injectedConnection;
        case ConnectionType.WALLET_CONNECT2:
          return walletConnectConnection;
        case ConnectionType.NETWORK:
          return networkConnection;
        default:
          return networkConnection;
      }
    }
  };
};
