import React from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MetamaskIcon from "../../assets/images/metamask.png";
import TrustIcon from "../../assets/images/trust.png";
import Wlconnect from "../../assets/images/wallet-connect.svg";
import CloseIcon from "../../assets/images/close-icon.svg";
import styles from "./styles.module.scss";
import classNames from "classnames";
import { actions } from "../../redux/wallet.redux";
import { useDispatch } from "react-redux";
import useAnalyticsEventTracker from "../../services/useAnalyticsEventTracker";
import { ConnectionType, useGetConnection } from "../../connectors";

toast.configure();

export default function WalletPopup(props) {
  const { setOpenPopup } = props;
  const getConnections = useGetConnection();
  const dispatch = useDispatch();
  const gaEventTracker = useAnalyticsEventTracker("Wallet");

  const connectMetamask = async () => {
    if (window.ethereum) {
      if (window.ethereum.isMetaMask) {
        try {
          const connection = getConnections(ConnectionType.INJECTED);
          await connection.connector.activate();
          dispatch(actions.updateSelectedWalletAction(connection.type));
        } catch (e) {
          console.log(e);
        }
        gaEventTracker("Connect");
        setOpenPopup(false);
      } else {
        toast.error("Please install Metamask first", {
          autoClose: 5000,
          theme: "colored",
          position: "top-center",
        });
      }
    } else {
      toast.error("Please install Metamask first", {
        autoClose: 5000,
        theme: "colored",
        position: "top-center",
      });
    }
  };

  const connectTrustWallet = async () => {
    if (window.ethereum) {
      if (window.ethereum.isMetaMask) {
        toast.error(
          "Please install Trust wallet and open the website on Trust/DApps",
          { autoClose: 5000, theme: "colored", position: "top-center" }
        );
      } else {
        try {
          const connection = getConnections(ConnectionType.INJECTED);
          await connection.connector.activate();
          dispatch(actions.updateSelectedWalletAction(connection.type));
        } catch (e) {
          console.log(e);
        }
        gaEventTracker("Connect");
        setOpenPopup(false);
      }
    } else {
      toast.error(
        "Please install Trust wallet and open the website on Trust/DApps",
        { autoClose: 5000, theme: "colored", position: "top-center" }
      );
    }
  };

  const connectWalletConnect = async () => {
    try {
      const connection = getConnections(ConnectionType.WALLET_CONNECT2);
      await connection.connector.activate();
      dispatch(actions.updateSelectedWalletAction(connection.type));
    } catch (e) {
      console.log(e);
    }
    gaEventTracker("Connect");
    setOpenPopup(false);
  };

  return (
    <div className={"popup-container"}>
      <div className={styles.container}>
        <div className={styles.header}>
          <h4>Connect to a wallet</h4>
          <div className="cursor-pointer">
            <img src={CloseIcon} alt="" onClick={() => setOpenPopup(false)} />
          </div>
        </div>
        <div className={styles.body}>
          <div
            className={classNames(
              "flex justify-between items-center",
              styles.walletItem
            )}
            onClick={() => connectMetamask()}
          >
            <h5 className={styles.walletLabel}>Metamask</h5>
            <div className={styles.walletIcon}>
              <img src={MetamaskIcon} alt="" className={styles.walletIcon} />
            </div>
          </div>
          <div
            className={classNames(
              "flex justify-between items-center",
              styles.walletItem
            )}
            onClick={() => connectTrustWallet()}
          >
            <h5 className={styles.walletLabel}>Trust wallet</h5>
            <div className={styles.walletIcon}>
              <img src={TrustIcon} alt="" className={styles.walletIcon} />
            </div>
          </div>
          <div
            className={classNames(
              "flex justify-between items-center",
              styles.walletItem
            )}
            onClick={() => connectWalletConnect()}
          >
            <h5 className={styles.walletLabel}>WalletConnect</h5>
            <div className={styles.walletIcon}>
              <img src={Wlconnect} alt="" className={styles.walletIcon} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
