import {useEffect} from 'react';

import {networkConnection, useGetConnection} from '../connectors';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../redux/wallet.redux';

async function connect(connector) {
  try {
    if (connector.connectEagerly) {
      await connector.connectEagerly();
    } else {
      await connector.activate();
    }
  } catch (error) {
    console.debug(`web3-react eager connection error: ${error}`);
  }
}

export const useEagerlyConnect = () => {
  const dispatch = useDispatch();

  const {selectedWallet} = useSelector((state) => state.wallet);
  const getConnection = useGetConnection();

  useEffect(() => {
    let selectedConnection;
    if (selectedWallet) {
      try {
        selectedConnection = getConnection(selectedWallet);
      } catch {
        dispatch(actions.updateSelectedWalletAction(undefined));
      }
    }
    connect(networkConnection.connector);
    if (selectedConnection) {
      connect(selectedConnection.connector);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
}
