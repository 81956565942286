import deLocale from '../locale/de.json';
import enLocale from '../locale/en.json';

export const actionTypes = {
    RefreshCount: '[RefreshCount] Action',
    Web3Client: '[Web3Client] Action',
    SetLocale: '[SetLocale] Action',
    UpdateStakeAmount: '[UpdateStakeAmount] Action',
};

const i18n = {
    de: deLocale,
    en: enLocale,
}

const initialState = {
    refreshCount: 0,
    web3Client: null,
    locale: i18n['de'],
    lang: 'de',
    stakingAmount: 0,
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.RefreshCount: {
            return {
                ...state,
                refreshCount: state.refreshCount + 1,
            };
        }
        case actionTypes.SetLocale: {
            return {
                ...state,
                locale: i18n[action.payload || 'de'],
                lang: action.payload || 'de',
            };
        }
        case actionTypes.UpdateStakeAmount: {
            return {
                ...state,
                stakingAmount: action.payload,
            };
        }
        default:
            return state;
    }
};

export const actions = {
    changeRefresh: () => ({
        type: actionTypes.RefreshCount,
    }),
    setLocale: (data) => ({
        type: actionTypes.SetLocale,
        payload: data,
    }),
    updateStakeAmount: (data) => ({
        type: actionTypes.UpdateStakeAmount,
        payload: data,
    }),
};
