import React, { useState, useCallback, useEffect, useRef } from 'react';
import PulseLoader from 'react-spinners/PulseLoader';
import CloseIcon from '../../assets/images/close-circle.svg';
import BteIcon from '../../assets/images/betero-icon.svg';
import BnbIcon from '../../assets/images/bnb-icon-new.svg';
import EthIcon from '../../assets/images/eth-token.png';
import CroIcon from '../../assets/images/cronos-pair.svg';
import LockIcon from '../../assets/images/lock-icon.svg';
import ArrowRightIcon from '../../assets/images/arrow-right.svg';
import ArrowLeftIcon from '../../assets/images/arrow-left.png';
import TokenMainInfo from '../TokenMainInfo';
import Button from '../Button';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../../redux/common.redux';
import { calRemainDate, parseBNumber, readableNumber } from '../../services/utils';
import classNames from 'classnames';
import StakingForm from './StakingForm';
import PoolDetailsPanel from './PoolDetailsPanel';
import { DECIMALS } from '../../abis/constants';
import useAnalyticsEventTracker from '../../services/useAnalyticsEventTracker';
import { useWeb3React } from '@web3-react/core';
import { Contract } from 'ethers';

const TokenDetailPopup = (props) => {
  const dispatch = useDispatch();
  const { locale, refreshCount } = useSelector(state => state.common);
  const {
    contract,
    vesting,
    setOpenPopup,
    btePrice,
    lpPrice,
    userStakedToken,
    userStakedBTE,
    userStakedBNB,
    userEarnedBTE,
    userStakedETH,
    userStakedCRO,
    stakedBTE,
    stakedBNB,
    stakedETH,
    stakedCRO,
    liquidity,
    apr,
    apy,
  } = props;
  const {account, provider} = useWeb3React();
  const containerRef = useRef();
  const gaEventTracker = useAnalyticsEventTracker('Token');
  const [approved, setApproved] = useState(false);
  const [loading, setLoading] = useState(false);
  const [harvesting, setHarvesting] = useState(false);
  const [userBalance, setUserBalance] = useState(0);
  const [remainTime, setRemainTime] = useState(`${contract.vesting} ${locale['months']}`);
  const [withdrawable, setWithdrawable] = useState(true);
  // const [vestedPeriod, setVestedPeriod] = useState('0 months');
  const [formName, setFormName] = useState('DEPOSIT'); // DEPOSIT | WITHDRAW | POOL_INFO

  const userStakedSecondToken = {
    'BNB': {amount: userStakedBNB.toFixed(4), icon: BnbIcon},
    'ETH': {amount: userStakedETH.toFixed(4), icon: EthIcon},
    'CRO': {amount: userStakedCRO.toFixed(4), icon: CroIcon},
  };

  const initProcess = useCallback(async () => {
    try {
      if (!account) return;
      setLoading(true);
      const signer = provider.getSigner(account);
      const farmContract = new Contract(contract.address, contract.abi, signer);
      const tokenContract = new Contract(contract.tokenAddress, contract.tokenAbi, signer);

      const allowance = await tokenContract.allowance(account, contract.address);
      setApproved(allowance !== '0');

      const balanceOf = await tokenContract.balanceOf(account);
      const user_balance = parseBNumber(balanceOf, DECIMALS);
      setUserBalance(user_balance);

      const userInfo = await farmContract.userInfo(account);
      const lastDepositedAt = parseInt(userInfo?.lastDepositedAt) * 1000; // in milisecond
      if (lastDepositedAt > 0) {
        const releaseTime = lastDepositedAt + vesting;
        setRemainTime(calRemainDate(releaseTime, locale));
        setWithdrawable(releaseTime < new Date().getTime());
      } else {
        setRemainTime(`${contract.vesting} ${locale['months']}`);
      }

      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  }, [account, provider, contract, vesting, locale])

  useEffect(() => {
    initProcess();
  }, [initProcess, refreshCount])

  const handleHarvest = async () => {
    if (!account) return;
    if (userEarnedBTE <= 0.0) return;

    try {
      setHarvesting(true);
      const signer = provider.getSigner(account);
      const farmContract = new Contract(contract.address, contract.abi, signer);
      if (contract.type === 'MULTIPLE') {
        const tx = await farmContract.harvest(account);
        await tx.wait();
      } else {
        const tx = await farmContract.harvest();
        await tx.wait();
      }
      gaEventTracker('Harvest');
      setHarvesting(false);
      dispatch(actions.changeRefresh());
    } catch (err) {
      setHarvesting(false);
      console.log(err);
    }
  }

  const onClose = () => {
    setOpenPopup(false);
    dispatch(actions.updateStakeAmount(0));
  }

  return (
    <div className='popup-container'>
      <div ref={containerRef} className='relative bg-secondary rounded-xl w-full max-w-4xl h-full md:h-auto non-scrollbar-box'>
        <div className='hidden md:block absolute top-8 right-5 md:right-10' onClick={onClose}>
          <img src={CloseIcon} alt="" className='w-5 h-5' />
        </div>
        <div className='block md:hidden pt-7 px-6.5' onClick={onClose}>
          <img src={ArrowLeftIcon} alt="" className='w-5 h-4' />
        </div>
        <div className='flex-1 px-6.5 md:px-9 pt-7 pb-0 md:pb-7'>
          <TokenMainInfo
            contract={contract}
            userStakedToken={userStakedToken}
            userEarnedBTE={userEarnedBTE}
            lpPrice={lpPrice}
            btePrice={btePrice}
            apy={apy}
            vesting={vesting}
          />
        </div>
        <div className='block md:flex md:flex-row-reverse px-6.5 md:px-14 py-6'>
          <div className='w-full md:w-3/5 pl-0 md:pl-7 flex flex-col'>
            <div className='flex-1'>
              {(formName === 'DEPOSIT' || formName === 'WITHDRAW') && (
                <StakingForm
                  contract={contract}
                  userStakedToken={userStakedToken}
                  userBalance={userBalance}
                  btePrice={btePrice}
                  lpPrice={lpPrice}
                  apy={apy}
                  userEarnedBTE={userEarnedBTE}
                  vesting={vesting}
                  approved={approved}
                  formName={formName}
                  setFormName={setFormName}
                  withdrawable={withdrawable}
                  dataLoading={loading}
                />
              )}
              {formName === 'POOL_INFO' && (
                <PoolDetailsPanel
                  contract={contract}
                  liquidity={liquidity}
                  stakedBNB={stakedBNB}
                  stakedBTE={stakedBTE}
                  stakedETH={stakedETH}
                  stakedCRO={stakedCRO}
                  apr={apr}
                />
              )}
            </div>
            <div className='pt-5 flex justify-center relative h-50px'>
              <div
                className='flex'
                onClick={() => setFormName(formName === 'POOL_INFO' ? 'DEPOSIT' : 'POOL_INFO')}
              >
                <div className={classNames(
                  'h-2 rounded-full mr-1 cursor-pointer',
                  { 'w-8 bg-gray-light': formName !== 'POOL_INFO' },
                  { 'w-2 bg-gray-dark': formName === 'POOL_INFO' }
                )} />
                <div className={classNames(
                  'h-2 rounded-full cursor-pointer',
                  { 'w-8 bg-gray-light': formName === 'POOL_INFO' },
                  { 'w-2 bg-gray-dark': formName !== 'POOL_INFO' }
                )} />
              </div>
              <div
                className='absolute top-4 right-0 flex items-center cursor-pointer'
                onClick={() => setFormName(formName === 'POOL_INFO' ? 'DEPOSIT' : 'POOL_INFO')}
              >
                {formName !== 'POOL_INFO' && (
                  <p className='text-12 text-gray-light'>{locale['pool_details']}</p>
                )}
                {formName === 'POOL_INFO' && (
                  <p className='text-12 text-gray-light'>{locale['deposit']}</p>
                )}
                <img src={ArrowRightIcon} alt="" className='w-4 h-3 ml-3' />
              </div>
            </div>
          </div>
          <div className='w-full md:w-2/5 flex flex-col'>
            <div className='pb-6 pt-3 md:pt-6'>
              <h5 className='text-15 font-medium text-gray-light mb-2.5'>{locale['total_bte_earned']}</h5>
              <div className='flex items-center mb-8'>
                <img src={BteIcon} alt="" className='w-10 h-10' />
                <div className='pl-3'>
                  <h5 className='text-19 font-semibold leading-none'>
                    {readableNumber(userEarnedBTE.toFixed(2))} BTE
                  </h5>
                  <p className='text-12 text-gray-light'>
                    ${readableNumber((userEarnedBTE * btePrice).toFixed(2))}
                  </p>
                </div>
              </div>
              <Button
                type='contained'
                className={classNames(
                  'h-50px',
                  // {'bg-gray-light': userEarnedBTE <= 0.0}
                )}
                onClick={handleHarvest}
              >
                {harvesting ? (
                  <div className='mt-2'>
                    <PulseLoader color={'#fff'} size={12} margin={6} />
                  </div>
                ) : (
                  <span>{locale['harvest']}</span>
                )}
              </Button>
            </div>
            <div className='bg-stone rounded-xl px-4.5 pt-4.5 pb-6 flex-1'>
              <p className='text-12 text-gray-light mb-1'>
                {contract.type === 'MULTIPLE'
                  ? `${contract.name} LP ${locale['staked']}`
                  : `BTE ${locale['staked']}`
                }
              </p>
              <div className='flex justify-between mb-6.5'>
                <div>
                  <div className='flex items-center'>
                    <img src={BteIcon} alt="" className='w-6 h-6' />
                    <h5 className='text-19 font-medium ml-3'>
                      {readableNumber(userStakedBTE.toFixed(2))}
                    </h5>
                  </div>
                  {contract.type === 'MULTIPLE' ? (
                    <>
                      <p className='text-12 text-gray-light leading-none pl-2'>+</p>
                      <div className='flex items-center'>
                        <img src={userStakedSecondToken[contract.secondToken]?.['icon']} alt="" className='w-6 h-6' />
                        <h5 className='text-19 font-medium ml-3'>
                          {readableNumber(userStakedSecondToken[contract.secondToken]?.['amount'])}
                        </h5>
                      </div>
                    </>
                  ) : (
                    <div className='h-10'></div>
                  )}
                </div>
                <div className=''>
                  <span className='text-12 text-gray-light'>
                    ${contract.type === 'MULTIPLE'
                      ? readableNumber((userStakedToken * lpPrice).toFixed(2))
                      : readableNumber((userStakedToken * btePrice).toFixed(2))}
                  </span>
                </div>
              </div>
              <div>
                {userStakedBTE > 0.0 ? (
                  <Button
                    type='outlined'
                    className={`h-50px ${withdrawable ? 'opacity-100' : 'opacity-30'}`}
                    onClick={() => {
                      if (withdrawable) {
                        containerRef.current?.scrollTo(0, 0);
                        setFormName('WITHDRAW');
                      }
                    }}
                  >
                    {!withdrawable && (
                      <img src={LockIcon} alt="" className='w-3 h-4 mb-1 mr-3' />
                    )}
                    <span>{locale['withdraw']}</span>
                  </Button>
                ) : (
                  <div className='h-50px'></div>
                )}
              </div>
            </div>
            {!withdrawable ? (
              <p className='text-10 text-center py-2.5 px-3 h-50px'>
                {locale['note']} {remainTime}
              </p>
            ) : (
              <div className='h-50px'></div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default TokenDetailPopup;
