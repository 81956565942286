import {useMemo} from 'react';

import {Web3ReactProvider} from '@web3-react/core';
import {useEagerlyConnect, useOrderedConnections} from '../hooks';

export const Web3Provider = ({children}) => {
  useEagerlyConnect();
  const connections = useOrderedConnections();
  const connectors = connections.map(({hooks, connector}) => [connector, hooks]);
  const key = useMemo(() => connections.map((connection) => connection.name).join('-'), [connections]);
  return (
    <Web3ReactProvider connectors={connectors} key={key}>
      {children}
    </Web3ReactProvider>
  );
};
