import {useMemo} from 'react';
import { useSelector } from 'react-redux';
import { ConnectionType, useGetConnection } from '../connectors';

const SELECTABLE_WALLETS = [ConnectionType.INJECTED, ConnectionType.WALLET_CONNECT2];

export const useOrderedConnections = () => {
  const {selectedWallet} = useSelector((state) => state.wallet);
  const getConnection = useGetConnection();
  return useMemo(() => {
    const orderedConnectionTypes = [];

    if (selectedWallet) {
      orderedConnectionTypes.push(selectedWallet);
    }
    orderedConnectionTypes.push(...SELECTABLE_WALLETS.filter((wallet) => wallet !== selectedWallet));

    // Add network connection last as it should be the fallback.
    orderedConnectionTypes.push(ConnectionType.NETWORK);

    return orderedConnectionTypes.map((connectionType) => getConnection(connectionType));
  }, [getConnection, selectedWallet]);
};
