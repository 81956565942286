import {configureStore} from '@reduxjs/toolkit';
import {load, save} from 'redux-localstorage-simple';

import rootReducer from './rootReducer';

const PERSISTED_KEYS = ['wallet'];

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({thunk: false, immutableCheck: false, serializableCheck: false}).concat(
      save({states: PERSISTED_KEYS, debounce: 100}),
    ),
    preloadedState: load({states: PERSISTED_KEYS}),
    });

export { store };
