export const actionTypes = {
    UpdateWallet: '[UpdateWallet] Action',
    UpdateSelectedWallet: '[UpdateSelectedWallet] Action',
};

const initialState = {
    wallet: undefined,
    selectedWallet: undefined,
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UpdateWallet: {
            return {
                ...state,
                wallet: action.payload,
            };
        }
        case actionTypes.UpdateSelectedWallet: {
            return {
                ...state,
                selectedWallet: action.payload,
            };
        }
        default:
            return state;
    }
};

export const actions = {
    updateWalletAction: (data) => ({
        type: actionTypes.UpdateWallet,
        payload: data
    }),
    updateSelectedWalletAction: (data) => ({
        type: actionTypes.UpdateSelectedWallet,
        payload: data
    }),
};
