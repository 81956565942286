import axios from "axios";

export const getCountryCode = async () => {
    try {
        const res = await axios.get("https://geolocation-db.com/json/");
        const code = res?.data?.country_code;
        return code === 'DE' ? 'de' : 'en';
    } catch (err) {
        return 'de';
    }
}
