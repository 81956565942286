import React from 'react';
import { useSelector } from 'react-redux';
import LinkIcon from '../../assets/images/copy_icon.png';
import { readableNumber } from '../../services/utils';

const PoolDetailsPanel = ({
  contract,
  liquidity,
  stakedBTE,
  stakedBNB,
  stakedETH,
  stakedCRO,
  apr
}) => {
  const { locale } = useSelector(state => state.common);
  const stakedAmounts = {
    'BNB': stakedBNB.toFixed(4),
    'ETH': stakedETH.toFixed(4),
    'CRO': stakedCRO.toFixed(4),
  }

  return (
    <div
      className='border-2 border-gray-dark rounded-xl px-4 md:px-5 pt-5 pb-6 h-full'
    >
      <p className='text-15 text-gray-light'>{locale['pool_details']}</p>
      <div className='pt-5 pb-10'>
        <div className='flex items-center justify-between mb-3'>
          <p className='text-12'>{locale['liquidity']}</p>
          <p className='text-12'>${readableNumber(liquidity.toFixed(2))}</p>
        </div>
        <div className='flex items-center justify-between mb-3'>
          <p className='text-12'>BTE {locale['staked']}</p>
          <p className='text-12'>{readableNumber(stakedBTE.toFixed(2))}</p>
        </div>
        {contract.type === 'MULTIPLE' && (
          <div className='flex items-center justify-between mb-3'>
            <p className='text-12'>{contract.secondToken} {locale['staked']}</p>
            <p className='text-12'>{readableNumber(stakedAmounts[contract.secondToken])}</p>
          </div>
        )}
        <div className='flex items-center justify-between'>
          <p className='text-12'>APR</p>
          <p className='text-12'>{readableNumber(apr.toFixed(2))}%</p>
        </div>
      </div>
      <a href={contract.contractLink} target={'_blank'} rel="noreferrer" className='flex items-center'>
        <p className='text-12 mr-5'>{locale['view_contract']}</p>
        <img src={LinkIcon} alt="" className='w-3 h-3' />
      </a>
    </div>
  )
}

export default PoolDetailsPanel;