import React from 'react';
import CloseIcon from '../../assets/images/close-icon.svg';
import styles from './styles.module.scss';


export default function DisclaimerPopup(props) {
    const { setOpenPopup } = props;

    return (
        <div className={styles.root}>
            <div className={styles.container}>
                    <div className={styles.header}>
                        <h4>Farm and Staking Disclaimer</h4>
                        <div className='cursor-pointer'>
                            <img src={CloseIcon} alt="" onClick={() => setOpenPopup(false)}/>
                        </div>
                    </div>
                    <div className='pt-10'>
                        Coming soon...
                    </div>
            </div>
            <div className={styles.overlay} onClick={() => setOpenPopup(false)}/>
        </div>
    )
}