import React, { useState, useEffect, useCallback } from 'react';
import EnglandFlag from '../../assets/images/england-flag.svg';
import GermanFlag from '../../assets/images/german-flag.svg';
import { useDispatch } from 'react-redux';
import { actions } from '../../redux/common.redux';
import { getCountryCode } from '../../services/api';
import { useRef } from 'react';
import classNames from 'classnames';

const SelectCountry = (props) => {
  const dispatch = useDispatch();

  const countries = useRef([
    {name: 'English', shortName: 'Eng', code: 'en', image: EnglandFlag},
    {name: 'Deutsch', shortName: 'Deu', code: 'de', image: GermanFlag},
  ]);
  const [openMenu, setOpenMenu] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState({});

  const initProcess = useCallback(async () => {
    const countryCode = localStorage.getItem('countryCode');
    const code = countryCode || await getCountryCode();
    console.log('code: ', code);
    localStorage.setItem('countryCode', code)
    dispatch(actions.setLocale(code));
    const country = countries.current.find(x => x.code === code);
    setSelectedCountry(country);
  }, [dispatch]);

  useEffect(() => {
    initProcess();
  }, [initProcess])
  

  const handleClick = (country) => {
    setSelectedCountry(country);
    localStorage.setItem('countryCode', country.code);
    dispatch(actions.setLocale(country.code));
    setOpenMenu(false);
  }

  return (
    <div className='relative'>
      <div
        className='flex items-center justify-center px-4 cursor-pointer'
        onClick={() => setOpenMenu(true)}
      >
        <p className='text-15 px-3'>{selectedCountry.shortName}</p>
        <img src={selectedCountry.image} alt="" className='w-8 h-4 object-fill' />
      </div>
      {openMenu && (
        <>
          <div className='z-10 bg-secondary absolute top-12 right-0 rounded-lg shadow overflow-hidden p-2'>
            {countries.current.map((item, index) => {
              return (
                <div
                  key={index}
                  className={classNames(
                    'flex items-center pl-4 pr-9 py-2 cursor-pointer hover:opacity-100',
                    selectedCountry.code === item.code ? 'opacity-100' : 'opacity-50'
                  )}
                  onClick={() => handleClick(item)}
                >
                  <img src={item.image} alt="" className='w-8 h-4 object-fill' />
                  <p className='text-14 px-2'>{item.name}</p>
                </div>
              )
            })}
          </div>
          <div className='overlay' onClick={() => setOpenMenu(false)} />
        </>
      )}
    </div>
  )
}

export default SelectCountry;
