import ClipLoader from "react-spinners/ClipLoader";
import Button from "../../../components/Button";
import PuffLoader from "react-spinners/PuffLoader";
import CheckIcon from "../../../assets/images/check-icon.svg";
import ArrowDownIcon from "../../../assets/images/arrow-down.png";
import CloseIcon from "../../../assets/images/close-circle.svg";
import BTEIcon from "../../../assets/images/betero-icon.svg";
import { useMemo } from "react";

const Loader = ({ loading }) =>
  loading && (
    <div className="flex w-full h-full items-center justify-center">
      <ClipLoader color={"#63C127"} size={80} />
    </div>
  );

const MigrationDetails = ({ fromInput, toInput }) => {
  const fromVal = parseFloat(fromInput).toFixed(2);
  return (
    <div className="flex flex-col w-full h-full items-center justify-center">
      <div className="flex flex-col items-start justtify-center w-full gap-5">
        <h5 className="text-15 font-semibold leading-none text-gray-light">
          From
        </h5>

        <div className="pl-1 flex items-center gap-3">
          <img src={BTEIcon} alt="" className="w-8  h-8" />
          <p className="">Old BTE</p>
          <p className="ml-2">{fromVal}</p>
        </div>
      </div>
      <div className="px-10 my-5">
        <img src={ArrowDownIcon} alt="" className="w-3 h-4" />
      </div>
      <div className="flex flex-col items-start justtify-center w-full gap-5">
        <h5 className="text-15 font-semibold leading-none text-gray-light">
          To
        </h5>
        <div className="pl-1 flex items-center gap-3">
          <img src={BTEIcon} alt="" className="w-8  h-8" />
          <p className="">New BTE</p>
          <p className="ml-2">{toInput}</p>
        </div>
      </div>
    </div>
  );
};

const Steps = ({ step, fromInput, toInput }) => {
  switch (step) {
    case 0:
      return (
        <div className="flex flex-col items-center justify-center h-full gap-2 py-2 w-full max-sm:w-full">
          <img src={BTEIcon} alt="" className="w-11  h-11" />
          <p className="text-10 md:text-12 mt-5">Approve Old BTE tokens</p>
        </div>
      );
    case 1:
      return <MigrationDetails fromInput={fromInput} toInput={toInput} />;
    case 2:
      return (
        <>
          <div className="flex items-center justify-center relative self-center mt-5">
            <PuffLoader size={70} color={"#63C127"} />
            <div className="absolute top-6.5 flex justify-center items-center w-11 h-11 bg-green-primary rounded-full">
              <img src={CheckIcon} alt="" className="w-full h-full" />
            </div>
          </div>
          <div className="flex flex-col w-full h-full items-center justify-center">
            <MigrationDetails fromInput={fromInput} toInput={toInput} />
          </div>
          <p className="text-[8px] text-gray-light">
            Important: The BTE token migration is not instant. You will receive
            your tokens in your wallet automatically, according to the schedule
            in our migration blog article.
          </p>
        </>
      );
    default:
      return;
  }
};

const MigrationModal = ({
  step,
  loading,
  handleClose,
  approveCoins,
  migrateTokens,
  fromInput,
  toInput,
}) => {
  const header = useMemo(
    () => ({
      0: "Approve BTE",
      1: loading ? "Processing..." : "Confirm BTE Migration",
      2: "Successful!",
    }),
    [loading]
  );

  const buttonLabel = useMemo(() => {
    if (loading) return "...";
    return step === 0 ? "Approve Old BTE" : "Migrate BTE";
  }, [loading, step]);

  return (
    <div className="fixed top-0 right-0 left-0 bottom-0 h-screen w-screen backdrop-blur z-[100]">
      <div className="flex w-full h-full">
        <div className="flex flex-col w-80 h-96 m-auto items-start px-4 md:px-6 py-4 md:py-5 bg-secondary rounded-xl max-w-96 shadow-2xl shadow-green-900/50 drop-shadow mx-auto">
          <div className="flex justify-between w-full max-sm:w-full">
            <h1 className="text-15 text-gray-light font-medium m-auto">
              {header[step]}
            </h1>
            <img
              src={CloseIcon}
              alt=""
              className="w-5 h-5"
              onClick={handleClose}
            />
          </div>
          <Loader loading={loading} />
          {!loading && (
            <Steps step={step} fromInput={fromInput} toInput={toInput} />
          )}

          {step !== 2 && (
            <Button
              type={loading ? "disabled" : "contained"}
              compact
              max
              className="flex items-center justify-center px-4 h-50px cursor-pointer w-60 mt-auto mb-5 self-center max-sm:order-2"
              onClick={() => (step === 0 ? approveCoins() : migrateTokens())}
            >
              <span
                className={`font-montserrat font-bold ${
                  loading && "animate-pulse"
                }`}
              >
                {buttonLabel}
              </span>
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default MigrationModal;
